export const baseInfoCell = [
  {
    title: '公文标题',
    key: 'biaoti'
  },
  {
    title: '发文字号',
    key: 'documentNumber'
  },
  {
    title: '公文密级程度',
    key: 'miji',
    renderKey: 'flow_secrecy_degree'
  },
  {
    title: '公文紧急程度',
    key: 'jjcd',
    renderKey: 'flow_urgency_degree'
  },
  {
    title: '选择分类',
    key: 'xzfn',
    renderKey: 'flow_file_type'
  }
]
export const personCell = [
  {
    title: '拟稿单位/人',
    key: 'draftId'
  },
  {
    title: '会稿单位/人',
    key: 'meetId'
  },
  {
    title: '核稿人',
    key: 'checkIds'
  },
  {
    title: '主送',
    key: 'mainIds'
  },
  {
    title: '抄送',
    key: 'sendIds'
  }
]
